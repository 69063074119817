import {
  Stack, 
  Flex, 
  useTheme 
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Title } from 'components/ui';
import { useIsMobileScreen } from 'hook';

const StyledParagraph = styled.p(
  ({ mScreen }) => `
    font-size: ${mScreen ? '1.2rem' : '1.4rem'};
    font-weight: 100;
  `
);

export const AboutUs = () => {
  const theme = useTheme();
  const [isMobileScreen] = useIsMobileScreen();

  return (
    <Flex
      id='about-us'
      w='100%'
      pt='4rem'
      pb='6rem'
      px='1.4rem'
      justifyContent='center'
      backgroundColor={theme.colors.softGray}
    >
      <Stack id='nosotros' maxW='56rem' textAlign='center' gap='2rem'>
        <Title color={theme.colors.blueGreen} title='Empresa' />
        <StyledParagraph mScreen={isMobileScreen}>
          Con la mejor y mas avanzada tecnología, nuestro equipo se desempeña bajo el más estricto
          concepto de responsabilidad y compromiso para garantizar los mejores resultados y en el menor
          tiempo posible. Llegamos con nuestros servicios a todo el país.
        </StyledParagraph>
      </Stack>
    </Flex>
  );
};
