import styled from '@emotion/styled';
import { Button, Link } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';

const StyledButtonWtp = styled(Button)(
  ({ theme }) => `
    width: 16rem;
    border-radius: 2rem;
    padding: 1.6rem;
    font-size: 1rem;
    background: ${theme.colors.blueGreen};
    color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.darkBlueGreen};
    };
  `
);

export const WhatsappButton = () => (
  <StyledButtonWtp display='flex' alignSelf='center'>
    <Link 
      href='https://wa.me/+5493513948522?text=Hola, como va? Acabo de visitar postprocesos.com.' 
      target='_blank' 
      textTransform='uppercase' 
      display='flex' 
      alignItems='center' 
      gap='.5rem' 
      letterSpacing='0.1rem' 
      textDecor='none'
      _hover={{
        color: 'inherit'
      }}
    >
      <FaWhatsapp fontSize='1.6rem' />
      Escribinos
    </Link>
  </StyledButtonWtp>
);
