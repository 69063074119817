import { 
  Flex, 
  Stack, 
  Image, 
  useTheme, 
  Heading,
  UnorderedList,
  ListItem,
  ListIcon
} from '@chakra-ui/react';
import { Title } from 'components/ui';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useIsMobileScreen } from 'hook';
import { data } from './data';

export const Services = () => {
  const theme = useTheme();
  const [isMobileScreen] = useIsMobileScreen();
  return (
    <Stack
      id='services'
      width='100%'
      pt='4rem'
      pb='6rem'
      px='2rem'
      textAlign='center'
      gap='2rem'
      backgroundColor={theme.colors.blueGreen}
    >
      <Title color={theme.colors.white} title='Servicios' />
      <Stack gap='2.8rem' alignItems='center'>
        {data?.map(({
          id, 
          title, 
          items, 
          imgAlt, 
          imgSrc 
        }) => (
          <Flex maxW='60rem' key={id} flexDirection={id % 2 === 0 ? 'row' : 'row-reverse'} gap='2rem' flexWrap='wrap' justifyContent='center'>
            <Image src={imgSrc} alt={imgAlt} boxSize='20rem' />
            <Stack gap='.6rem' textAlign={isMobileScreen ? 'center' : 'start'}>
              <Heading color='white'>{ title }</Heading>
              <UnorderedList pl='1rem' listStyleType='none'>
                {items?.map((value) => (
                  <ListItem 
                    key={value} 
                    textAlign='start' 
                    color='white'
                    fontSize='1.1rem'
                    whiteSpace='pre-line'
                    mt='.2rem'
                  >
                    <ListIcon as={CheckCircleIcon} color='green.200' />
                    { value }
                  </ListItem>
                ))}
              </UnorderedList>
            </Stack>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
};
