import imgServicio1 from 'assets/img_servicio1.png';
import imgServicio2 from 'assets/img_servicio2.png';
import imgServicio3 from 'assets/img_servicio3.png';

export const data = [{
  id: '1',
  title: 'Agrimensura Legal',
  items: [
    'Antecedentes dominiales y catastrales',
    'Mensuras y amojonamientos',
    'Asesoramiento y peritajes',
    'Certificado de amojonamientos',
    'Consorcios parcelarios',
    'Mensura en propiedad horizontal',
    'Valuaciones y tasaciones',
    'Constitución de estado parcelario',
  ],
  imgSrc: imgServicio1,
  imgAlt: 'Postprocesos'
}, {
  id: '2',
  title: 'Topografía',
  items: [
    'Relevamientos planialtimétricos',
    'Replanteo de estructuras y obras civiles',
    'Estudio, proyecto y ejecución de caminos \n rurales y urbanos',
    'Nivelaciones en general',
    'Cómputos para movimientos de suelo',
    'Movimientos de suelo',
    'Dominios para riego',
    'Proyectos de riego y drenaje',
    'Batimetrías',
    'Construcción de puntos fijos planialtimétricos',
  ],
  imgSrc: imgServicio2,
  imgAlt: 'Postprocesos'
}, {
  id: '3',
  title: 'Cad',
  items: [
    'Confección de planos digitales',
    'Superposición de imágenes satelita con \n digitalizaciones cartográficas',
    'Modelos 3D',
  ],
  imgSrc: imgServicio3,
  imgAlt: 'Postprocesos'
}, {
  id: '4',
  title: 'Apoyo de campo',
  items: [
    'Posicionamiento GPS',
    'Inspección de apoyo de campo',
    'Vinculaciones con GPS',
  ],
  imgSrc: imgServicio1,
  imgAlt: 'Postprocesos'
}];
