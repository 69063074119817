import { VStack } from '@chakra-ui/react';
import {
  Header,
  Footer,
  Hero,
  AboutUs,
  Services,
} from 'components/sections';

export const App = () => (
  <VStack spacing={0}>
    <Header />
    <Hero />
    <AboutUs />
    <Services />
    <Footer />
  </VStack>
);
