export const data = [{
  id: '1',
  title: 'Inicio',
  href: '#hero'
}, {
  id: '2',
  title: 'Nosotros',
  href: '#about-us'
}, {
  id: '3',
  title: 'Servicios',
  href: '#services'
}, {
  id: '4',
  title: 'Contacto',
  href: '#contact'
}];
