import {
  Box, 
  Stack, 
  Flex, 
  Icon,
  Text,
} from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { Title } from 'components/ui';
import styled from '@emotion/styled';
import { useIsMobileScreen } from 'hook';
import { data } from './data';

const StyledMaps = styled.iframe(
  ({ mScreen }) => `
    width: ${mScreen ? '100%' : '24rem'};
    height: ${mScreen ? '20rem' : '16rem'};
  `
);

export const Footer = () => {
  const theme = useTheme();
  const [isMobileScreen] = useIsMobileScreen();
  
  return (
    <Stack 
      id='contact'
      width='100%'
      px='1.4rem'
      pt='4rem'
      backgroundColor={theme.colors.softGray}
      textAlign='center'
      gap='1.4rem'
    >
      <Title color={theme.colors.blueGreen} title='Contacto' />
      <Flex display='flex' flexDirection='row' justifyContent='center' gap='2rem' flexWrap='wrap'>
        <Stack justifyContent='center' color={theme.colors.blueGreen} gap='.6rem'>
          {data?.map(({ id, icon, title }) => (
            <Flex key={id} gap='.8rem' alignItems='center'>
              <Icon as={icon} boxSize='1.2rem' />
              <Text fontSize='1.2rem' fontWeight={400} whiteSpace='pre-line'>{ title }</Text>
            </Flex>
          ))}
        </Stack> 
        <StyledMaps 
          mScreen={isMobileScreen} 
          title='mapas' 
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.821058332332!2d-64.21729948536029!3d-31.41905588140321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a27589bedafd%3A0x19635b0725ee43c8!2sFructuoso%20Rivera%202189%2C%20C%C3%B3rdoba!5e0!3m2!1ses-419!2sar!4v1662057554184!5m2!1ses-419!2sar' 
        />
      </Flex>
      <Stack width='full' pt='1rem' spacing={0}>
        <Box height='1px' width='full' bgColor='gray.300' />
        <Flex h='4rem' justifyContent='center' alignItems='center'>
          <Text>Copyright © 2023 | postprocesos.com</Text>
        </Flex>
      </Stack>
    </Stack>
  );
};
