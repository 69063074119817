import {
  Box, useTheme, Link, Flex, Stack
} from '@chakra-ui/react';
import imgHeroFondo from 'assets/img_hero_fondo_gradiente.png';
import styled from '@emotion/styled';
import { useIsMobileScreen } from 'hook';
import { WhatsappButton } from 'components/ui';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';

const StyledMainTitle = styled.h1(
  ({ theme, mScreen }) => `
  font-size: ${mScreen ? '2.4rem' : '5.6rem'};
  font-weight: ${mScreen ? '700' : '900'};
  line-height: ${mScreen ? '3.2rem' : '6rem'};
  :first-letter {
    color: ${theme.colors.blueGreen};
  };
  `
);

const StyledSubtitle = styled.h2(
  ({ theme, mScreen }) => `
    font-size: ${mScreen ? '1.2rem' : '1.8rem'};
    margin-top: ${mScreen ? '1rem' : '1.6rem'};
    color: ${theme.colors.blueGreen};
  `
);

export const Hero = () => {
  const theme = useTheme();
  const [isMobileScreen] = useIsMobileScreen();

  return (
    <Stack 
      id='hero' 
      w='100%' 
      h='100dvh'
      p='10rem 1.4rem 0'
      backgroundImage={imgHeroFondo} 
      backgroundSize='cover' 
      bgPosition='center' 
      backgroundRepeat='no-repeat' 
      textAlign='center' 
      gap='2rem'
    >
      <Box flexGrow={1}>
        <StyledMainTitle mScreen={isMobileScreen}>POST PROCESOS</StyledMainTitle>
        <StyledSubtitle mScreen={isMobileScreen}>INGENIERÍA EN AGRIMENSURA</StyledSubtitle>
        <Flex mt='4rem' justifyContent='center'>
          <WhatsappButton />
        </Flex>
      </Box>
      
      <Flex pb='2.4rem' justifyContent='center'>
        <Link href='#about-us'>
          <BsFillArrowDownCircleFill 
            color={theme.colors.blueGreen} 
            fontSize='3.2rem' 
          />
        </Link>
      </Flex>      
    </Stack>
  );
};
