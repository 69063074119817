export const chakraTheme = {
  styles: {
    global: ({ theme }) => ({
      body: {
        margin: '0',
        fontFamily: theme.fonts.montserrat,
      }
    })
  },
  colors: {
    black: '#000',
    white: '#fff',
    blueGreen: '#128c7e',
    darkBlueGreen: '#167368',
    darkGray: '#333333',
    softGray: '#f2f2f2',
    transparentGray: '#d1d1d1ee',
    smoothGray: '#3E3D36',

  },
  fonts: {
    montserrat: '"Montserrat", sans-serif',
  },
};
