import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useIsMobileScreen } from 'hook';

const StyledTitle = styled.h2(
  ({ propColor, mScreen }) => `
    font-size: ${mScreen ? '2rem' : '2.5rem'};
    font-weight: ${mScreen ? 600 : 900};
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    color: ${propColor};
  `
);

export const Title = ({ title, color }) => {
  const [isMobileScreen] = useIsMobileScreen();

  return (
    <StyledTitle propColor={color} mScreen={isMobileScreen}>{ title }</StyledTitle>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string
};

Title.defaultProps = {
  color: null
};
