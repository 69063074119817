import {
  Box,
  useTheme,
  Flex,
  HStack,
  Image,
  Link,
  Menu,
  MenuList,
  MenuItem,
  useBreakpointValue,
  IconButton,
  Divider,
  MenuButton
} from '@chakra-ui/react';
import logoHeader from 'assets/logo_header.png';
import styled from '@emotion/styled';
import { IoFilterCircleOutline } from 'react-icons/io5';
import { data } from './data';

const StyledLink = styled(Link)(
  ({ theme }) => `
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.colors.smoothGray};
    &:hover {
      text-decoration: none;
      color: ${theme.colors.black};
    };
  `
);

const StyledMobileLink = styled(Link)(
  ({ theme }) => `
    width: 16rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.colors.smoothGray};
    &:hover {
      text-decoration: none;
      color: ${theme.colors.black};
    };
  `
);

export const Header = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const theme = useTheme();

  return (
    <Box as='section' w='100%' position='fixed' zIndex={1000}>
      <Box as='nav' bgColor={theme.colors.transparentGray}>
        <HStack px='3rem' py='.4rem' justify='space-between'>
          <Link href='#hero'>
            <Image 
              src={logoHeader} 
              alt='logo' 
              boxSize='4rem'
            />
          </Link>
          {isDesktop ? (
            <Flex justify='space-between'>
              <Box display='flex' justifyContent='space-evenly' alignItems='center' gap='3rem' mx='2rem' my='0.5rem'>
                {data?.map(({ id, title, href }) => <StyledLink key={id} href={href}>{ title }</StyledLink>)}
              </Box>
            </Flex>
          ) : (
            <Menu>
              <MenuButton>
                <IoFilterCircleOutline as={IconButton} color={theme.colors.blueGreen} fontSize='3.6rem' />
              </MenuButton>
              <MenuList fontSize='.9rem'>
                {data?.map(({ id, title, href }, index) => (
                  <Box key={id}>
                    <MenuItem>
                      <StyledMobileLink href={href}>{ title }</StyledMobileLink>
                    </MenuItem>
                    {index < (data.length - 1) && <Divider />}
                  </Box>
                ))}
              </MenuList>
            </Menu>
          )}
        </HStack>
      </Box>
    </Box>
  );
};
