import { FaPhoneAlt } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import { GrMail } from 'react-icons/gr';

export const data = [{
  id: '1',
  icon: IoLocationSharp,
  title: 'Fructuoso Rivera 2189, \n Córdoba Capital'
}, {
  id: '2',
  icon: FaPhoneAlt,
  title: '+54 9 351 394-8522'
}, {
  id: '3',
  icon: GrMail,
  title: 'diazpereyra@postprocesos.com'
}];
